/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "../components/style.css"

const Actualite = ({ children, detailStatus  }) => {
  let idportfolio = `#port`+children.id;
  let idportfolio2 = `port`+children.id;
  let src = null;
  let title = "";
  let description ="";
  if(children.relationships.field_act_img_d && children.relationships.field_act_img_d.length > 0 ){
    src =`https://grandest.data4citizen.com/${children.relationships.field_act_img_d[0].uri.url}`;
  }
  else if(children.relationships.field_act_img_g && children.relationships.field_act_img_g.length > 0 ){
    src =`https://grandest.data4citizen.com/${children.relationships.field_act_img_g[0].uri.url}`;
  }
  else {
    src="/images/1.jpg";
  }
  if(children.field_act_texte_colonne_g && children.field_act_texte_colonne_g.length > 0) {
    description = children.field_act_texte_colonne_g[0].value;

  }
  if(description === "" && children.field_act_texte_g && children.field_act_texte_g.length > 0 && children.field_act_texte_g[0].value != null){
    description = children.field_act_texte_g[0].value;

  }
  if(description === "" && children.field_act_texte_d  && children.field_act_texte_d.value != null){
    description = children.field_act_texte_d.value;
  } 
  if(children.field_act_entete_img_d && children.field_act_entete_img_d.value ){
    title = children.field_act_entete_img_d.value;
  }
  else if(children.field_act_entete_img_g && children.field_act_entete_img_g.value ) {
    title = children.field_act_entete_img_g.value;
  }

  if(detailStatus === "true" ) {
    return (
      <>
      
      <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href={idportfolio}>
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img className="img-fluid img-fluid-size center" src={src} alt=""  />
                            </a>
                            <div className="portfolio-caption">
                                <div className="portfolio-caption-heading "><p className="autoShowHidepub">{title}</p></div>
                                {/* <div className="portfolio-caption-subheading text-muted"><center></center><a className="btn btn-dark btn-dark-pub mx-2" style={{backgroundColor: "#fed136 !important"}}data-toggle="modal" href={idportfolio}>Consulter</a></div> */}
                            </div>
                        </div>
                    </div>
            <div className="portfolio-modal modal fade" id={idportfolio2} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal"><img src="/images/close-icon.svg" alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                   
                                    <h3 className="text-uppercase ">{title}</h3>
                                    <p className="item-intro text-muted">{children.created}</p>
                                    <img className="img-fluid d-block mx-auto imgblog" src={src} alt="" width="574px"/>
                                    <div dangerouslySetInnerHTML={{ __html: description }}></div>                                   
                                    
                                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                                        <i className="fas fa-times mr-1"></i>
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
  } else {
    return (
      <>
      
                        <div className="timeline-image"><a data-toggle="modal" href={idportfolio} > <img id ="imgblog" className="center rounded-circle img-fluid" src={src} alt=""  width="136px" height="136px" style={{height: 136 +"px !important"}}/></a></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>{children.created}</h4>
                                <h4 className="subheading"></h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted" dangerouslySetInnerHTML={{ __html: title}}></p></div> 
                            {/* <a className="btn btn-dark mx-2" data-toggle="modal" href={idportfolio}>Consulter</a> */}
                            {/* <div className="timeline-body"><p className="text-muted" dangerouslySetInnerHTML={{ __html: description}}></p></div> */}
                        </div>
          <div className="portfolio-modal modal fade" id={idportfolio2} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal"><img src="/images/close-icon.svg" alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                   
                                    <h3 className="text-uppercase">{title}</h3>
                                    <p className="item-intro text-muted">{children.created}</p>
                                    <img className="img-fluid d-block mx-auto imgblog" src={src} alt=""/>
                                    <div dangerouslySetInnerHTML={{ __html: description }}></div>                                   

                                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                                        <i className="fas fa-times mr-1"></i>
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </>
    )
  }

  

 


}

Actualite.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Actualite
